import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as S from "./styled";

const Pillars = () => {
  const { rectangle, logo, pillars } = useStaticQuery(
    graphql`
      query {
        rectangle: file(relativePath: { eq: "CatCommon/rectangle.svg" }) { publicURL }
        logo: file(relativePath: { eq: "CatBigData/logo.svg" }) { publicURL }
        pillars: file(relativePath: { eq: "Solutions/Pillars/pillars.png" }) { publicURL }
      }
    `
  );

  return (
    <S.Pillars>
      <S.PurpleColumn />
      <S.ItemsWrapper>
        <img src={ pillars.publicURL } className="item pillars-image" alt="Pilares" />
        <S.TitleWrapper className="item">
          {/* <img src={ rectangle.publicURL } className="title-icon" alt="Retângulo Roxo" /> */}
          <S.Title>
            Descomplicada<br />
            e intuitiva
          </S.Title>
          <S.Spacer />
          <S.Subtitle>
            A CAT engloba diversas soluções de análises e<br />
            inteligência artificial, um time inteiro de<br />
            tecnologia e ciência de dados em um só lugar.
          </S.Subtitle>
        </S.TitleWrapper>
      </S.ItemsWrapper>
    </S.Pillars>
  );
};

export default Pillars;
