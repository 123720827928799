import styled from "styled-components";

export const Wrapper = styled.div`
  width: 640px;
  height: 697px;

  .backgroundImg {
    width: 640px;
    height: 373px;
    background: linear-gradient(180deg, rgba(193, 143, 255, 0) 11.46%, rgba(31, 10, 56, 0.21) 100%);
    border-radius: 51px 51px 0px 0px;
  }
`

export const MainWrapper = styled.div`
  height: 100%;
  padding: 25px 50px 0 50px;
  background-color: #FFFFFF;
`

export const TitleWrapper = styled.div`
  display: table;  
`

export const IconWrapper = styled.div`
  display: table-cell;
  width: 30%;
  height: 100%;  
  vertical-align: middle;

  .title-icon {    
    width: 60%;
    height: 60%;    
    margin: 0 auto;
  }
`

export const TextWrapper = styled.div`
  display: table-cell;
  width: 70%;
  background: #FFFFFF;
  vertical-align: middle; 
`

export const Audience = styled.label`
  font-family: Exo2;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #2A2944;
`

export const Title = styled.h1`
  font-family: Exo2;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: #2A2944;
`

export const ContentWrapper = styled.div`
  padding-top: 25px;
  p {
    font-family: Exo2;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    
    padding-top: 20px;
    color: #201E2B;
  }
`

export const EndLine = styled.div`
  height: 12px;
  width: 100%;
`