import styled from "styled-components";

export const BusinessValue = styled.div`
  width: 100%;
  position: relative;  
  background-color: #E0E0E0;
  padding: 3rem var(--default-padding-desktop);
`
export const PurpleColumn = styled.div`
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #8630F2;
`;

export const TitleWrapper = styled.div`
  text-align: center;

  h1 {
    display: inline;
  }

  .title-icon {
    width: 29px;
    height: 29px;
    margin: auto 40px;
    vertical-align: 15%;
    display: inline;
  }
`

export const Title = styled.h1`  
  font-family: Exo2;
  font-weight: 700;
  font-size: 80px;
  line-height: 96px;
  color: #2A2944;
  text-align: center;
`;

export const Items = styled.div`  
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;  
  align-items: center;
`;

export const Item = styled.div`
  margin-left: 30px;
  width: 220px;
  display: inline;

  .first-item {
    border-radius: 30px 0px 0px 0px;
  }

  .last-item {
    border-radius: 0px 30px 0px 0px;    
  }
`;

export const ItemImageWrapper = styled.div`  
  position: relative;  
  height: 140px;
  width: 210px;
  border: 2px solid #A0A0D7;
  box-sizing: border-box;

  .item-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: auto;
    height: auto;    
    max-height: 100%;
    max-width: 100%;
  }
`;

export const ItemDescription = styled.div`
  padding-top: 20px;  

  font-family: Exo2;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: #1D1D1D;

  strong {
    font-weight: 700;
    color: #8630F2;
  }
`;