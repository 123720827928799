import React from "react";

import Template from "../Template";

import PropTypes from "prop-types";

const ClientProfiles = ({ active }) => {
  return (
    <div style={ active
      ? { 'display':'block' }
      : { 'display':'none' } }>
      <Template id="ClientProfiles" color="#5658D9" audience="Ferramenta de Vendas" title="Perfís de Clientes" style={ { 'display': active ? 'block' : 'none' } }>
        <p>
          A CAT auxilia o seu time a entender, com profundidade, o perfil e as demandas do seu público-alvo, tendo como premissa os discursos e as interações que ocorrem espontaneamente com cada um deles.
        </p>
        <p>
          Crie estratégias de relacionamento e posicionamento eficientes tendo como base a própria voz do cliente e não mais sugestões ou pesquisas amostrais.
        </p>
        <p>
          Estabeleça conexões mais profundas e autênticas com dados sobre a persona dos seus clientes, perfis detratores, promotores, informações demográficas, geográficas e outros contextos específicos do seu segmento.
        </p>
      </Template>
    </div>
  );
};
      
ClientProfiles.propTypes = {
  active: PropTypes.bool
};
      
export default ClientProfiles;