import React from "react";

import Template from "../Template";

import PropTypes from "prop-types";

const ReputationManagement = ({ active }) => {
  return (
    <div style={ active
      ? { 'display':'block' }
      : { 'display':'none' } }>
      <Template id="ReputationManagement" color="#48BF7A" audience="Ferramenta de Compliance" title="Gestão de Reputação" style={ { 'display': active ? 'block' : 'none' } }>
        <p>
          Acompanhe em tempo real a imagem da sua marca perante o público.
        </p>
        <p>
          Cada vez mais as pessoas compartilham com seus seguidores as suas boas ou más experiências em relação às marcas.
        </p>
        <p>
          A Inteligência Artificial da CAT monitora todas as vezes que a sua marca é citada em canais de relacionamento, identificando o contexto de cada um desses comentários.
        </p>
        <p>
          Com isso, seu time poderá agir rapidamente para aproveitar oportunidades, reduzir eventuais danos à imagem da ou prevenir possíveis crises.
        </p>
      </Template>
    </div>
  );
};
      
ReputationManagement.propTypes = {
  active: PropTypes.bool
};
      
export default ReputationManagement;