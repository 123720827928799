import React from "react";
import * as S from "./styled";

import PropTypes from "prop-types";

class SolutionItem extends React.Component {
  render() {
    return (
      <S.Wrapper id={`si${this.props.id}`} onClick={ this.props.onClick } style={ this.props.active
          ? { 'borderColor': this.props.color, 'boxShadow': '0px 14px 25px ' + this.props.color + '66'}
          : { 'borderColor': this.props.color, 'cursor': 'pointer' }
      }>
          <S.IconWrapper style={ this.props.active
              ? { 'backgroundColor': this.props.color }
              : { 'backgroundColor': 'transparent' }
          }>
              <img  className="title-icon" alt="Icone"
                src={ this.props.active
                  ? require(`../../../images/Solutions/SolutionItem/${this.props.icon}-on.svg`).default
                  : require(`../../../images/Solutions/SolutionItem/${this.props.icon}-off.svg`).default }
                style={ this.props.active
                  ? { 'color': this.props.color }
                  : { 'color': '#FFFFFF' } }
              />
          </S.IconWrapper>
          <S.TextWrapper style={ this.props.active
              ? { 'backgroundColor': '#FFFFFF' }
              : { 'backgroundColor': 'transparent' }
          }>
              <S.Audience style={ this.props.active
                  ? { 'color': '#2A2944' }
                  : { 'color': '#FFFFFF' }
              }>
                  { this.props.audience || "Sem audiência" }
              </S.Audience>
              <S.Title style={ this.props.active
                  ? { 'color': '#2A2944' }
                  : { 'color': '#FFFFFF' }
              }>
                  { this.props.title || "Sem título" }
              </S.Title>
          </S.TextWrapper>
      </S.Wrapper>    
    );
  };
};

SolutionItem.propTypes = {
  id: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  audience: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool
};

export default SolutionItem;