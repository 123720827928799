import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import * as S from "./styled";

const BusinessValue = () => {
  const { titleIcon, checkIcon, insightIcon, loveIcon, timeIcon } = useStaticQuery(
    graphql`
      query {
        titleIcon: file(relativePath: { eq: "CatCommon/rectangle.svg" }) { publicURL }
        checkIcon: file(relativePath: { eq: "Solutions/BusinessValue/checkIcon.svg" }) { publicURL }
        insightIcon: file(relativePath: { eq: "Solutions/BusinessValue/insightIcon.svg" }) { publicURL }
        loveIcon: file(relativePath: { eq: "Solutions/BusinessValue/loveIcon.svg" }) { publicURL }
        timeIcon: file(relativePath: { eq: "Solutions/BusinessValue/timeIcon.svg" }) { publicURL }
      }
    `
  );

  return (
    <S.BusinessValue>
      <S.PurpleColumn />

      <S.TitleWrapper>
        <S.Title>
          <img src={titleIcon.publicURL} className="title-icon" alt="Retângulo Roxo" />
          A CAT tem um valor inestimável
          <img src={titleIcon.publicURL} className="title-icon" alt="Retângulo Roxo" />
          <br />
          para o seu negócio
        </S.Title>
      </S.TitleWrapper>

      <S.Items>
        <S.Item>
          <S.ItemImageWrapper className="first-item">
            <img src={checkIcon.publicURL} className="item-image" />
          </S.ItemImageWrapper>
          <S.ItemDescription>
            <strong>Garanta</strong> o<br />
            compliance<br />
            da sua<br />
            organização
        </S.ItemDescription>
        </S.Item>

        <S.Item>
          <S.ItemImageWrapper>
            <img src={insightIcon.publicURL} className="item-image" />
          </S.ItemImageWrapper>
          <S.ItemDescription>
            <strong>Empodere</strong> os<br />
            times da sua<br />
            empresa com<br />
            <strong>insights</strong>
          </S.ItemDescription>
        </S.Item>

      <S.Item>
        <S.ItemImageWrapper>
          <img src={loveIcon.publicURL} className="item-image" />
        </S.ItemImageWrapper>
        <S.ItemDescription>
          <strong>Potencialize</strong> a<br />
          experiência do<br />
          seu cliente com<br />
          sua marca
        </S.ItemDescription>
      </S.Item>

      <S.Item>
        <S.ItemImageWrapper className="last-item">
          <img src={timeIcon.publicURL} className="item-image" />
        </S.ItemImageWrapper>
        <S.ItemDescription>
          <strong>Economize</strong><br />
          <strong>muito tempo</strong><br />
          e dinheiro<br />
          com análises
        </S.ItemDescription>
      </S.Item>

      </S.Items>

    </S.BusinessValue>
  );
};

export default BusinessValue;