import React from "react";

import CTABanner from "../../CtaBanner";

const AboutBanner = () => {
  const catThemeItemsDark = {
    main: "var(--cat-primary)",
    color: "var(--cat-primary)",
    boldTextColor: "#ad76f4",
    gradient: "var(--dark-gradient)",
    textColor: "#dcdcdc",
  };

  return (    
    <CTABanner
      title="A ferramenta idel para a sua estratégia"
      subtitle="Descubra para que serve a CAT e como ela é aplicada no dia a dia"
      theme={catThemeItemsDark}
      buttonText="Explorar"
      buttonLink="/"
      openAsExternalLink
    />
  );
};

export default AboutBanner;