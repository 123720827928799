import React from "react";

import Template from "../Template";

import PropTypes from "prop-types";

const PeopleAnalytics = ({ active }) => {
  return (
    <div style={ active
      ? { 'display':'block' }
      : { 'display':'none' } }>
      <Template id="PeopleAnalytics" color="#0FB7C1" audience="Ferramenta de Gente e Gestão" title="People Analytics" style={ { 'display': active ? 'block' : 'none' } }>
        <p>
          A CAT auxilia a sua empresa a construir times altamente comprometidos com os objetivos do seu negócio e aderentes à sua cultura organizacional.
        </p>
        <p>
          A inteligência artificial da ferramenta analisa toda a sua jornada de recrutamento, seleção e retenção de talentos, para identificar padrões e comportamentos em toda a equipe de “Gente e Gestão”.
        </p>
        <p>
          Esta analise vai muito além do benefício de assertividade na seleção de pessoas, porque enxerga dados invisíveis e não estruturados, fornecendo insights detalhados sobre a cultura da sua empresa, seus modelos de liderança, perfis psicográficos e sobre o engajamento de todas as pessoas que compõem o seu time e movem o seu negócio.
      </p>
      </Template>
    </div>
  );
};
      
PeopleAnalytics.propTypes = {
  active: PropTypes.bool
};
      
export default PeopleAnalytics;