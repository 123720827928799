import React from "react";

import SolutionTable from "../SolutionTable";

import { useStaticQuery, graphql } from "gatsby";

import * as S from "./styled";

const Main = () => {
  const { titleIcon } = useStaticQuery(
    graphql`
      query {
        titleIcon: file(relativePath: { eq: "CatCommon/rectangle.svg" }) { publicURL }
      }
    `
  );

  return (
    <S.Main>
      <S.TitleWrapper>
        <S.Title>
          <img src={titleIcon.publicURL} className="title-icon" alt="Retângulo Roxo" />
          O seu desafio é a nossa expertise
          <img src={titleIcon.publicURL} className="title-icon" alt="Retângulo Roxo" />
        </S.Title>
        <S.Subtitle>
          Navegue pelas soluções da CAT e descubra como elas podem ajudar o seu negócio
        </S.Subtitle>
      </S.TitleWrapper>
      <SolutionTable />
    </S.Main>
  );
};

export default Main;