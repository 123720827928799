import React from "react";

import Template from "../Template";

import PropTypes from "prop-types";

const BrandContent = ({ active }) => {
  return (
    <div style={ active
      ? { 'display':'block' }
      : { 'display':'none' } }>
      <Template id="BrandContent" color="#D1A937" audience="Ferramenta de Marketing" title="Conteúdo para marcas">
        <p>
          A CAT monitora todos os canais de relacionamento da sua marca com o cliente para aproveitar a riqueza de informações disponibilizadas espontaneamente em cada interação.
        </p>
        <p>
          A inteligência artificial da ferramenta elenca tópicos, palavras-chave, menções e outros termos relacionados à sua marca, fornecendo insights valiosos para o seu time elaborar estratégias de relacionamento e de marketing de conteúdo com alto potencial de engajamento.
        </p>
      </Template>
    </div>
  );
};
      
BrandContent.propTypes = {
  active: PropTypes.bool
};
      
export default BrandContent;