import React from "react";

import Template from "../Template";

import PropTypes from "prop-types";

const RivalAnalysis = ({ active }) => {
  return (    
    <div style={ active
      ? { 'display':'block' }
      : { 'display':'none' } }>
      <Template id="RivalAnalysis" color="#EA6A6A" audience="Ferramenta de Marketing" title="Análise de Concorrentes">
        <p>
          A CAT auxilia o seu time a monitorar amplamente as ações de marketing e posicionamento das marcas concorrentes em canais de relacionamento.
        </p>
        <p>
          A inteligência artificial da ferramenta elenca tópicos, palavras-chave, menções e outros termos relacionados à marca do seu concorrente, fornecendo insights valiosos sobre táticas e estratégias de relacionamento que são positivas ou negativas em seu segmento, em contextos específicos ou assuntos sazonais.
        </p>
        <p>
          Entenda como seus concorrentes estão se posicionando, qual é a performance e os resultados dessas táticas e como seus clientes estão reagindo à essas ações. E então, adapte a sua própria estratégia de relacionamento.
        </p>
      </Template>
    </div>
  );
};
      
RivalAnalysis.propTypes = {
  active: PropTypes.bool
};
      
export default RivalAnalysis;