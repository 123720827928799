import React from "react";

import * as S from "./styled";

const Goal = () => {

  return (
    <S.Goal>
      <S.PurpleColumn />      
      <S.TitleWrapper>
        <S.Title>
          Dados invisíveis para humanos, são<br />
          nítidos para os olhos e ouvidos da<br />
          <strong>Inteligência Artificial</strong>
        </S.Title>
        <br />
        <S.Subtitle>
          "O objetivo da cat é transformar o Big Data e a<br />
          Ciência da Complexidade em algo fácil de entender e<br />
          útil para o seu negócio, contribuindo para que<br />          
          empresas <strong>tomem decisões melhores</strong> e possam<br />
          empenhar mais tempo sendo criativas e inovadoras,<br />
          tendo sempre o cliente no centro de tudo."
        </S.Subtitle>
        <br />
        <S.Person>
          <strong>André Carvalho</strong> CSO da cat
        </S.Person>
      </S.TitleWrapper>      
    </S.Goal>
  );
};

export default Goal;
