import styled from "styled-components";
import media from "styled-media-query";

export const Main = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
    background: #2A2944;
`

export const TitleWrapper = styled.div`
  margin: 40px 0;
  text-align: center;

  h1 {
    display: inline;
  }

  .title-icon {
    width: 29px;
    height: 29px;
    margin: auto 40px;
    vertical-align: 15%;
    display: inline;
  }
`

export const Title = styled.h1`  
  font-family: Exo2;
  font-weight: 700;
  font-size: 80px;
  line-weight: 96px;
  color: #FFFFFF;
  text-align: center;
`;

export const Subtitle = styled.h2`
  font-family: Exo2;
  font-weight: 400;
  font-size: 30px;
  line-weight: 36px;
  color: #FFFFFF;
  text-align: center;
`;