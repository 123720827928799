import React from "react";

import SolutionItem from "../SolutionItem";

import AlternateTopicMonitoring from "../SolutionMain/AlternateTopicMonitoring";
import BrandContent from "../SolutionMain/BrandContent";
import ClientProfiles from "../SolutionMain/ClientProfiles";
import PeopleAnalytics from "../SolutionMain/PeopleAnalytics";
import ReputationManagement from "../SolutionMain/ReputationManagement";
import RivalAnalysis from "../SolutionMain/RivalAnalysis";

import * as S from "./styled";

class SolutionTable extends React.Component {
  constructor (props){
    super(props);
  
    this.state = {
      BrandContent: true,
      RivalAnalysis: false,
      AlternateTopicMonitoring: false,
      ReputationManagement: false,
      ClientProfiles: false,
      PeopleAnalytics: false,
    };
  }

  ChangeSelection(itemName) {    
    this.setState( {
        BrandContent: false,
        RivalAnalysis: false,
        AlternateTopicMonitoring: false,
        ReputationManagement: false,
        ClientProfiles: false,
        PeopleAnalytics: false,
      },
      () => {        
        switch(itemName) {
          case 'BrandContent':
            this.setState( { BrandContent: true } ) 
            break;
          case 'RivalAnalysis':
            this.setState( { RivalAnalysis: true} ) 
            break;
          case 'AlternateTopicMonitoring':
            this.setState( { AlternateTopicMonitoring: true} ) 
            break;
          case 'ReputationManagement':
            this.setState( { ReputationManagement: true} ) 
            break;
          case 'ClientProfiles':            
            this.setState( { ClientProfiles: true} )             
            break;
          case 'PeopleAnalytics':
            this.setState( { PeopleAnalytics: true} ) 
            break;
          default:
            console.log('Not found.');
            break;
        }
      }
    );
  }

  render() {
    return (
      <S.ItemsTable>
        <S.ItemsMenu>
          <SolutionItem id="BrandContent" icon="magnifier" color="#D1A937"
            audience="Ferramenta de Marketing" title="Conteúdo para marcas" active={ this.state.BrandContent }
            onClick={ this.ChangeSelection.bind(this, 'BrandContent') } />
          <SolutionItem id="RivalAnalysis" icon="sims" color="#EA6A6A"
            audience="Ferramenta de Marketing" title="Análise de Concorrentes" active={ this.state.RivalAnalysis }
            onClick={ this.ChangeSelection.bind(this, 'RivalAnalysis') } />
          <SolutionItem id="AlternateTopicMonitoring" icon="mainStar" color="#EF5DA8"
            audience="Ferramenta de Marketing" title="Monitoramento de temas alternativos" active={ this.state.AlternateTopicMonitoring }
            onClick={ this.ChangeSelection.bind(this, 'AlternateTopicMonitoring') } />
          <SolutionItem id="ReputationManagement" icon="stars" color="#48BF7A"
            audience="Ferramenta de Compliance" title="Gestão de Reputação" active={ this.state.ReputationManagement }
            onClick={ this.ChangeSelection.bind(this, 'ReputationManagement') } />
          <SolutionItem id="ClientProfiles" icon="gear" color="#5658D9"
            audience="Ferramenta de Vendas" title="Perfil de Clientes" active={ this.state.ClientProfiles }
            onClick={ this.ChangeSelection.bind(this, 'ClientProfiles') } />
          <SolutionItem id="PeopleAnalytics" icon="chart" color="#0FB7C1"
            audience="Ferramenta de Gente e Gestão" title="People Analytics" active={ this.state.PeopleAnalytics }
            onClick={ this.ChangeSelection.bind(this, 'PeopleAnalytics') } />
        </S.ItemsMenu>
        <S.ItemsDescription>
          <BrandContent active={ this.state.BrandContent } />
          <RivalAnalysis active={ this.state.RivalAnalysis } />
          <AlternateTopicMonitoring active={ this.state.AlternateTopicMonitoring } />
          <ReputationManagement active={ this.state.ReputationManagement } />
          <ClientProfiles active={ this.state.ClientProfiles } />
          <PeopleAnalytics active={ this.state.PeopleAnalytics } />
        </S.ItemsDescription>
      </S.ItemsTable>
    );
  };
};

export default SolutionTable;