import React from "react";

import CTABanner from "../../CtaBanner";

const SalesBanner = () => {
  const catThemeItemsDark = {
    main: "var(--cat-primary)",
    color: "var(--cat-primary)",
    boldTextColor: "#ad76f4",
    gradient: "var(--dark-gradient)",
    textColor: "#dcdcdc",
  };

  return (    
    <CTABanner
      title="Evolua para a inteligência omnichannel"
      subtitle="Fale com um de nossos consultores"
      theme={catThemeItemsDark}
      buttonText="Chamar"
      buttonLink="/"
      openAsExternalLink
    />
  );
};

export default SalesBanner;