import styled from "styled-components";
import media from "styled-media-query";

export const Goal = styled.div`
  background-image: var(--cat-solutions-goal-background-image);
  background-size: 100%;
  padding: 3rem var(--default-padding-desktop);
  position: relative;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 100px) and (max-width: 1544px){
    background-size: cover;
  background-repeat: no-repeat;
  height: 450px;
  background-origin: border-box;
  
  }
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
 
  .solution-image {
    width: 80%;
    margin-top: 25px;
  }
  ${media.greaterThan("1200px")`
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
  background-origin: border-box;
`}
${media.greaterThan("3600px")`
background-size: cover;
background-repeat: no-repeat;
height: 400px;
background-origin: border-box;
`}
`;

export const PurpleColumn = styled.div`
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #8630F2;
`;

export const TitleWrapper = styled.div`padding-left: 10%;`;

export const Title = styled.label`
  display: block;
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 48px;
  color: #FFFFFF;
  strong {
    font-weight: bold;
    font-weight: 700;
    color: #8630F2;
  }
  ${media.greaterThan("3600px")`
  font-size: 23px;
  line-height: 28px;
`}
@media screen and (min-width: 100px) and (max-width: 1544px){
  font-size: 30px;
  
}
`;

export const Subtitle = styled.label`
  display: block;
  font-family: 'Exo2';
  font-weight: 400;
  font-size: 30px;
  color: #A0A0D7;
  strong {
    font-weight: 500;
    color: #FFFFFF;
  }
  ${media.greaterThan("3600px")`
  font-size: 15px;
`}
@media screen and (min-width: 100px) and (max-width: 1544px){
  font-size: 20px;
}
`;

export const Person = styled.label`
  display: block;
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 48px;
  color: #8630F2;
  strong {
    font-size: 48px;
    color: #FFFFFF;
  }

  ${media.greaterThan("3600px")`
  display: block;
  font-family: 'Exo2';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 48px;
  color: #8630F2;
  strong {
    font-size: 28px;
    color: #FFFFFF;
  }
  
`}
`;