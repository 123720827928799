import styled from "styled-components";
import media from "styled-media-query";

export const Pillars = styled.div`
  background-color: #FFFFFF;
  padding: 3rem var(--default-padding-desktop);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.lessThan("medium")`
    padding: var(--default-padding-mobile);
  `}
 
  .pillars-image {
    width: 700px;
    height: 720px;
  }

  ${media.greaterThan("2000px")`
  .pillars-image {
    width: 500px;
    height: 520px;
  }
`}

${media.greaterThan("3600px")`
  .pillars-image {
    width: 400px;
    height: 420px;
  }
`}

@media screen and (min-width: 100px) and (max-width: 1570px){
  .pillars-image {
    width: 370px;
    height: 390px;
  }
}
  
`;

export const PurpleColumn = styled.div`
  width: 10px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #8630F2;
`;

export const ItemsWrapper = styled.div`  
  display: flex;

  .item {
    margin: auto 40px;
    display: inline;    
  }
`;

export const TitleWrapper = styled.div`
  .title-icon {
    width: 15px;
    height: 15px;
    margin-top: 17px;
    float: left;
  }
`;

export const Title = styled.h1`  
  font-family: 'Exo2';
  font-weight: 700;
  font-size: 80px;
  color: #271C3E;
  ${media.greaterThan("3600px")`
  font-size: 50px;
`}
@media screen and (min-width: 100px) and (max-width: 1570px){
  font-size: 40px;
}
  `;
  
  export const Subtitle = styled.label`
  display: block;
  font-family: 'Exo2';
  font-weight: 400;
  font-size: 30px;
  color: #2A2944;
  ${media.greaterThan("3600px")`
  font-size: 20px;
`}
@media screen and (min-width: 100px) and (max-width: 1570px){
  font-size: 17px;
}
`;

export const Spacer = styled.div`
  height: 28px;
`;


