import React from "react";

import Template from "../Template";

import PropTypes from "prop-types";

const AlternateTopicMonitoring = ({ active }) => {
  return (
    <div style={ active
      ? { 'display':'block' }
      : { 'display':'none' } }>
      <Template id="AlternateTopicMonitoring" color="#EF5DA8" audience="Ferramenta de Marketing" title="Monitoramento de temas alternativos" style={ { 'display': active ? 'block' : 'none' } }>
        <p>
          A CAT auxilia a sua marca a construir uma reputação positiva e autêntica na perspectiva de responsabilidade social, ambiental e de governança.
        </p>
        <p>
          A ferramenta facilita o entendimento de demandas coletivas e a prática de ações voluntárias em prol da sociedade.
        </p>
        <p>
          Garanta que o discurso da sua empresa em todos os canais de interação com o cliente e através de seus colaboradores sejam íntegros, únicos e reforcem o compromisso da sua marca com temas alternativos e de interesse dos cidadãos.
        </p>
      </Template>
    </div>
  );
};
      
AlternateTopicMonitoring.propTypes = {
  active: PropTypes.bool
};
      
export default AlternateTopicMonitoring;