import styled from "styled-components";
import media from "styled-media-query";

export const HowWork = styled.div`
  width: 100%;  

  p {
    font-family: 'Exo2';
    font-weight: 500;
    font-size: 36px;
    color: #271C3E;
  }
  @media screen and (min-width: 2000px) and (max-width: 4544px){
    p {
      font-family: 'Exo2';
      font-weight: 500;
      font-size: 29px;
      color: #271C3E;
    }
  }
  @media screen and (min-width: 360px) and (max-width: 1700px){
    p {
      font-family: 'Exo2';
      font-weight: 500;
      font-size: 27px;
      color: #271C3E;
    }
  }

`

export const PanelOne = styled.div`
  width: 100%;
  // height: 839px;
  background-color: white;

  .logo {
    width: 834px;
    height: 509px;
    border-radius: 87px 87px 0px 0px;
  }
  @media screen and (min-width: 2000px) and (max-width: 4544px){
    .logo {
      width: 734px;
      height: 409px;
      border-radius: 87px 87px 0px 0px;
    }
  }
`

export const TitleWrapper = styled.div`
  margin: 40px 0;
  text-align: center;

  h1 {
    display: inline;
  }

  .title-icon {
    width: 29px;
    height: 29px;
    margin: auto 40px;
    vertical-align: 15%;
    display: inline;
  }
`

export const Title = styled.h1`  
  font-family: Exo2;
  font-weight: 700;
  font-size: 80px;
  color: #2A2944;
  text-align: center;
  @media screen and (min-width: 2000px) and (max-width: 4544px){
    font-size: 40px;
  }
`;

export const ItemsWrapper = styled.div`
  margin: 0 auto;
  width: 80%;
  display: table;
`

export const PanelOneItemLeft = styled.div`
  display: table-cell;
  width: 50%;
`

export const PanelOneItemRight = styled.div`
  display: table-cell;
  width: 50%;
  vertical-align: middle;
  text-align: right;
  
`

export const PanelOneItemRightInner = styled.div`
  display: inline-block;
  text-align: left;
`

export const PanelOnePurpleRectangle = styled.div`
  background-color: #8630F2;
  width: 848px;
  height: 304px;
  margin-top: -304px;
  @media screen and (min-width: 2000px) and (max-width: 4544px){
    width: 548px;
  }
  @media screen and (min-width: 360px) and (max-width: 1700px){
    width: 248px;
  }
`

export const PanelTwo = styled.div`
  width: 100%;
  // height: 680px;
  background-color: #E0E0E0;

  .logo {
    width: 1032px;
    height: 688px;    
  }
`

export const PanelTwoItemLeft = styled.div`
  display: table-cell;
  width: 40%;
  vertical-align: middle;
  text-align: right;
`

export const PanelTwoItemLeftInner = styled.div`
  display: inline-block;
  text-align: left;
`

export const PanelTwoItemRight = styled.div`
  display: table-cell;
  width: 60%;
 
`

export const PanelTwoPurpleRectangle = styled.div`
  background-color: #8630F2;
  width: 848px;
  height: 304px;
  margin-top: -312px;
  float: right;
  @media screen and (min-width: 2000px) and (max-width: 4544px){
    width: 658px;
  }
  @media screen and (min-width: 360px) and (max-width: 1540px){
    width: 580px;
  }
`

export const PanelThree = styled.div`
  width: 100%;
  // height: 839px;
  background-color: white;

  .logo {
    // width: 1023px;
    // height: 661px;
    width: 807;
    height: 521;    
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`

export const PanelThreeItemLeft = styled.div`
  display: table-cell;  
  width: 55%;
  vertical-align: bottom;
`

export const PanelThreeItemRight = styled.div`
  display: table-cell;
  width: 45%;
  vertical-align: middle;
  text-align: right;
`

export const PanelThreeItemRightInner = styled.div`
  display: inline-block;
  text-align: left;
`