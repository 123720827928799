import styled from "styled-components";

export const Wrapper = styled.div`
  width: 657px;
  height: 129px;    

  margin-bottom: 55px;
  display: table;

  border: 2px solid #000000;  
  box-sizing: border-box;  
`

export const IconWrapper = styled.div`
  display: table-cell;
  width: 185px;
  height: 100%;  
  vertical-align: middle;

  .title-icon {    
    width: 60%;
    height: 60%;    
    margin: 0 auto;
  }
`

export const TextWrapper = styled.div`
  display: table-cell;
  width: 472px;
  background: #FFFFFF;
  vertical-align: middle;
  padding: 0 60px;
`

export const Audience = styled.label`
  font-family: Exo2;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
`

export const Title = styled.h1`
  font-family: Exo2;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
`