import React from "react";
import "../bulma.scss";

import CatLayout from "../components/CatLayout";
import CatSolutionsAbout from "../components/Solutions/About";
import CatSolutionsAboutBanner from "../components/Solutions/AboutBanner";
import CatSolutionsHowWork from "../components/Solutions/HowWork";
import CatSolutionsMain from "../components/Solutions/Main";
import CatSolutionsSalesBanner from "../components/Solutions/SalesBanner";
import CatSolutionsPillars from "../components/Solutions/Pillars";
import CatSolutionsBusinessValue from "../components/Solutions/BusinessValue";
import CatSolutionsGoal from "../components/Solutions/Goal";

const Solucoes = () => {
  return (
    <CatLayout>
      <CatSolutionsAbout />
      <CatSolutionsAboutBanner />
      <CatSolutionsHowWork />
      <CatSolutionsMain />
      <CatSolutionsSalesBanner />
      <CatSolutionsPillars />
      <CatSolutionsBusinessValue />
      <CatSolutionsGoal />
      <CatSolutionsSalesBanner />
    </CatLayout>
  );
};

export default Solucoes;
