import React from "react";
import * as S from "./styled";

import PropTypes from "prop-types";

const SolutionMainTemplate = ({ id, children, color, audience, title }) => {
  return (
    <S.Wrapper id={`sm${id}`}>
      <img src={ require(`../../../../images/Solutions/SolutionMain/${id}-bg.png`).default } alt="Imagem de fundo" className="backgroundImg" />      
      <S.MainWrapper>
        <S.TitleWrapper>
          <S.IconWrapper>
            <img src={ require(`../../../../images/Solutions/SolutionMain/${id}-icon.svg`).default } className="title-icon" alt="Icone" />            
          </S.IconWrapper>
          <S.TextWrapper>
            <S.Audience>
              { audience || "Sem audiência" }
            </S.Audience>
            <S.Title>
              { title || "Sem título" }
            </S.Title>
          </S.TextWrapper>
        </S.TitleWrapper>
        <S.ContentWrapper>
          { children }
        </S.ContentWrapper>
      </S.MainWrapper>
      <S.EndLine style={ { 'backgroundColor': color } }>
        &nbsp;
      </S.EndLine>
    </S.Wrapper>
  );
};
  
SolutionMainTemplate.propTypes = {  
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  color: PropTypes.string,
  audience: PropTypes.string,
  title: PropTypes.string
};
  
export default SolutionMainTemplate;