import React from "react";

import { useStaticQuery, graphql } from "gatsby";

import * as S from "./styled";

const HowWork = () => {
  const { titleIcon, panelOneImage, panelTwoImage, panelThreeImage } = useStaticQuery(
    graphql`
      query {
        titleIcon: file(relativePath: { eq: "CatCommon/rectangle.svg" }) { publicURL }
        panelOneImage: file(relativePath: { eq: "Solutions/HowWork/PanelOneImage.png" }) { publicURL }
        panelTwoImage: file(relativePath: { eq: "Solutions/HowWork/PanelTwoImage.png" }) { publicURL }
        panelThreeImage: file(relativePath: { eq: "Solutions/HowWork/PanelThreeImage.png" }) { publicURL }
      }
    `
  );

  return (
    <S.HowWork>
      <S.PanelOne>        
        <S.TitleWrapper>
          <S.Title>
            <img src={titleIcon.publicURL} className="title-icon" alt="Retângulo Roxo" />
            Como funciona a inteligência
            <img src={titleIcon.publicURL} className="title-icon" alt="Retângulo Roxo" />
            <br />
            omnichannel da CAT ?
          </S.Title>
        </S.TitleWrapper>
        <S.ItemsWrapper>
          <S.PanelOneItemLeft>
            <img src={panelOneImage.publicURL} className="logo" />
          </S.PanelOneItemLeft>
          <S.PanelOneItemRight>
            <S.PanelOneItemRightInner>
              <p>
                A CAT é uma ferramenta que coleta<br />
                dados de voz ou texto de diversas<br />
                fontes da sua empresa, desde que<br />
                sejam canais de interação.
              </p>
              </S.PanelOneItemRightInner>
          </S.PanelOneItemRight>
        </S.ItemsWrapper>
        <S.PanelOnePurpleRectangle>
          &nbsp;
        </S.PanelOnePurpleRectangle>
      </S.PanelOne>
      <S.PanelTwo>
        <S.ItemsWrapper>
          <S.PanelTwoItemLeft>
            <S.PanelTwoItemLeftInner>
              <p>
                Utilizando uma Inteligência<br />
                Artificial e outras tecnologias<br />
                cognitivas, a ferramenta enquadra<br />
                as principais informações em um<br />
                dashboard visual, de modo a<br />
                oferecer um panorama claro,<br />
                simplificado e intuitivo sobre seus<br />
                clientes, produtos, serviços e<br />
                concorrentes.
              </p>
            </S.PanelTwoItemLeftInner>
          </S.PanelTwoItemLeft>
          <S.PanelTwoItemRight>
            <img src={panelTwoImage.publicURL} className="logo" />            
          </S.PanelTwoItemRight>
        </S.ItemsWrapper>        
        <S.PanelTwoPurpleRectangle>
          &nbsp;
        </S.PanelTwoPurpleRectangle>
      </S.PanelTwo>
      <S.PanelThree>
      <S.ItemsWrapper>
        <S.PanelThreeItemLeft>
          <img src={panelThreeImage.publicURL} className="logo" />
        </S.PanelThreeItemLeft>
        <S.PanelThreeItemRight>
          <S.PanelThreeItemRightInner>
            <p>
              Este panorama permitirá que os<br />
              principais times estratégicos da sua<br />
              organização tenham uma ideia mais<br />
              precisa do seu negócio, permitindo a<br />
              tomada de decisões mais<br />
              inteligentes, o aproveitamento de<br />
              oportunidades, a antecipação de<br />
              tendências e a mitigação de riscos<br />
              reputacionais associados à sua<br />
              marca.
            </p>
          </S.PanelThreeItemRightInner>
        </S.PanelThreeItemRight>
        {/* <S.PurpleRectangle>
          &nbsp;
        </S.PurpleRectangle> */}
        </S.ItemsWrapper>
      </S.PanelThree>
    </S.HowWork>
  );
};

export default HowWork;