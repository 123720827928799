import styled from "styled-components";

export const ItemsTable = styled.div`
  margin: 0 auto;
  padding-top: 20px;
  width: 80%;
  display: table;
`

export const ItemsMenu = styled.div`
  display: table-cell;  
  width: 50%;
  vertical-align: top;
`

export const ItemsDescription = styled.div`
  display: table-cell;
  width: 50%;
  vertical-align: top;
`