import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import * as S from "./styled";

const About = () => {
  const { rectangle } = useStaticQuery(
    graphql`
      query {
        rectangle: file(relativePath: { eq: "CatCommon/rectangle.svg" }) { publicURL }
      }
    `
  );

  return (
      <S.About>
        <S.Wrapper>
          <img src={ rectangle.publicURL } className="title-icon" alt="Retângulo Roxo" />
          <S.WrapperText>
            <S.Title>
              A solução ideal<br />
              para exponenciar<br />
              a sua estratégia<br />
            </S.Title>
            <S.Spacer />
            <S.Subtitle>
              A <strong>CAT</strong> é uma solução de <strong>Inteligência Artificial</strong><br />
              que analisa todos os canais de interação da sua<br />
              empresa com clientes e o mercado, fornecendo<br />
              dados que geram insights sobre <strong>Vendas</strong>,<br />
              <strong>Marketing</strong> e <strong>Compliance</strong>.
            </S.Subtitle>            
          </S.WrapperText>          
        </S.Wrapper>
        <S.Spacer />                
      </S.About>      
  );
};

export default About;